/* -------- Questionnaire -------- */

@media screen and (max-width: 991px) {
    .content {
        margin-left: 16px !important;
        margin-right: 16px !important;
        padding-right: 0 !important;
        width: auto !important;
    }

    .menu-etape-header {
        width: 100%;
        background-color: white;
        padding-bottom: 2%;
        display: flex !important;
        justify-content: center;
    }
}

@media screen and (max-width: 1016px) {
    .content {
        padding-top: var(--dsd-spacing-padding-medium);
        display: flex;
        flex-direction: column;
        margin-left: calc(354px + var(--dsd-spacing-padding-medium));
        padding-right: 50px;
        width: 50%;
    }

    .menu-etape-header {
        display:none
    }
}

@media screen and (min-width: 991px) {
    .content {
        padding-top: var(--dsd-spacing-padding-medium);
        display: flex;
        flex-direction: column;
        margin-left: calc(354px + var(--dsd-spacing-padding-medium));
        width: 55%;
    }

    .menu-etape-header {
        display:none
    }
}

/* ------------------------------- */

main {
    min-height: calc(104vh - (84px + 102.05px));
    display: flex;
    position: relative;
    overflow: hidden;
}


#nav-gabarit-transaction.gabarit-transaction .nav-gabarit-transaction-container {
    padding: 0 !important;
}