#nom-assure {
  text-transform: uppercase;
}

#container-assure p {
  line-height: 1.5 rem;
  margin-bottom: 0;
}

fieldset {
  border: none;
  margin-left: 0 !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
}

fieldset legend {
  max-width: 920px;
}

@media screen and (max-width: 639.98px) {
  #container-assure .dsd-grid {
    max-width: calc(100% - (-16px * 2));
  }
}

.question-transition-enter {
  opacity: 0;
}

.question-transition-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.question-transition-exit {
  opacity: 1;
}

.question-transition-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

h3 {
  color: white;
}

.ligne-haut-message-ra {
  margin-bottom: var(--dsd-spacing-padding-medium);
}
