.divider {
  background: white;
  height: 100%;
  width: 354px;
  position: absolute;
  left: 0;
  top: 0;
}

@media screen and (max-height: 680px) {
  .divider {
    height: 100%;
  }
}

@media screen and (max-width: 991px) {
  .divider {
    visibility: hidden;
  }
}
