.step-indicator {
    margin-left: var(--dsd-spacing-padding-medium);
    margin-top: var(--dsd-spacing-padding-medium);
}

@media screen and (max-width: 991px) {
    .step-indicator {
        width: auto;
        margin-top: 1%;
        margin-left: var(--dsd-spacing-padding-small);
        margin-right: var(--dsd-spacing-padding-small);
    }

    .step-indicator-container {
        width:100%;
        background-color: white;
        display: inline-block;
    }
}