.dsd-radio-group-wrapper {
  margin-bottom: 0px;
}

  .dsd-radio-host .dsd-radio-wrapper {
    padding-top: 0 !important;
    min-height: 0 !important;
  }

  div.dsd-radio-group-list {
    flex-direction: column;
  }
  
  #liste-puces {
    margin-bottom: 0px;
    margin-left: 12px;
    font-weight: normal;
  } 
  
  span.dsd-form-label-star {
    position: relative;
    left: -10px;
    margin-right: -10px;
  }
  
  div.dsd-radio-group-wrapper.dsd-is-error {
    margin-bottom: 0px;
  }
  
 .liste-erreur {
    color: #ca241a;
  }
  
  .dsd-is-error ul#liste-puces {
    padding-left: 9px;
  }
  
  #container-question span[slot="legend"] {
    color: #383838;
    position: relative;
    top: 3px;
    font-size: 1rem;
    line-height: 24px;
  }
  
  #groupe-bouton-radio,
  .dsd-is-error .dsd-radio-group-list,
  .dsd-form-error {
    margin-left: -10px;
  }
  
  dsd-radio:first-child .dsd-is-error span.dsd-radio-trigger-element {
    margin-left: 10px;
  }
  
  @media screen and (max-width: 991.98px) {
    legend.dsd-radio-group-legend {
      margin-left: 10px;
    }
  
    .dsd-is-error .dsd-radio-group-list,
    .dsd-form-error {
      margin-left: 2px;
    }
  
    .dsd-radio-group-list {
      margin-left: 10px;
    }
  }
  
  ul {
    margin-top: var(--dsd-spacing-padding-xsmall) !important;
  }

  .libelle-erreur {
    color: var(--dsd-color-border-button-error)
  }